import { isSet } from "../utils/isSet";

function handleLinkModification(link = null){
	if(!isSet(link)) return;

	if(link.classList.contains('download')){
		const inner_link = link.querySelector('.wp-block-button__link');

		if(!isSet(inner_link)) return;

		inner_link.setAttribute('download', '');

		return;
	}

	if(isSet(link.target) && link.target === "_blank"){
		link.insertAdjacentHTML(
			"beforeend", 
			`<svg class="new-window-icon" aria-hidden="true" role="img"><use href="#new-window-icon"></use></svg>`
		);

		link.setAttribute("aria-describedby", "new-win-desc");
	}

}

exports.handleLinkModification = handleLinkModification